import { onMounted, onBeforeMount, onBeforeUpdate, onUpdated, onBeforeUnmount,
	onUnmounted, onActivated, onDeactivated, onErrorCaptured, getCurrentInstance,
	reactive, toRefs, ref, provide
} from "vue";
import { useRouter } from 'vue-router'
import { baseUrlImg } from '@/utils/common'

export default {
	setup(props, context) {
		console.log("开始创建组件之前");
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		let dataAll = reactive({
			enterpriseData: {},
			baseUrlImg: baseUrlImg(),
			loginAlert: ref(false),
			show: ref(false),
			urlImg: ref(""),
			enterpriseModel: ref(0),
			images: ref([]),
			username: ref(""),
			password: ref(""),
			cateringCategoryList: ref([])
		});

		// 获取企业信息
		let getEnterpriseInfo = () =>{
			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/getEnterprise`),
				method: 'post',
				data: proxy.$http.adornData({
					'enterpriseQrNumber': proxy.$router.currentRoute.value.params.id,
				})
			}).then(({
				data
			}) => {
				console.log(data);
				if (data && data.code === 0) {
					dataAll.enterpriseData = data.data;
					dataAll.enterpriseModel = data.data.enterpriseModel;
					if (dataAll.enterpriseData.userTsMapList != null && dataAll.enterpriseData.userTsMapList[0] != undefined) {
						if (dataAll.enterpriseData.userTsMapList[0].userId == null) {
							dataAll.enterpriseData.userTsMapListEn = null;
						} else {
							let nameArr = dataAll.enterpriseData.userTsMapList.map((u) => { return u.userNikeName })
							dataAll.enterpriseData.userTsMapListEn = nameArr? (nameArr.length>1? nameArr.join("、") : nameArr[0]) : '暂无';
						}
					}
					sessionStorage.setItem('enterpriseQrNumber', proxy.$router.currentRoute.value.params.id);
					sessionStorage.setItem('enterpriseModel', dataAll.enterpriseModel);
					sessionStorage.setItem('enterpriseName', dataAll.enterpriseData.enterpriseName);
					sessionStorage.setItem('enterpriseId', dataAll.enterpriseData.enterpriseId);
					sessionStorage.setItem('enterpriseGame', dataAll.enterpriseData.enterpriseGame);
					sessionStorage.setItem('enterpriseUser', dataAll.enterpriseData.enterpriseUser);
					sessionStorage.setItem('userTsMapList', JSON.stringify(dataAll.enterpriseData.userTsMapList));
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast(data.msg);
				}
			})
		}
		
		getEnterpriseInfo()

		// 向其他的引用脚本传递变量
		provide('dataAll', dataAll);

		// 在挂载前执行某些代码
		onBeforeMount(() => {
			console.log('在挂载前执行某些代码');
		})

		// 在挂载后执行某些代码
		onMounted(() => {
			console.log("在挂载后执行某些代码");
		})
		
		// 在更新前前执行某些代码
		onBeforeUpdate(() => {
			console.log("在更新前前执行某些代码");
		})
		
		// 在更新后执行某些代码
		onUpdated(() => {
			console.log("在更新后执行某些代码");
		})
		
		// 在组件销毁前执行某些代码
		onBeforeUnmount(() => {
			console.log("在组件销毁前执行某些代码");
		})

		// 在组件销毁后执行某些代码
		onUnmounted(() => {
			console.log("在组件销毁后执行某些代码");
		})

		// 被包含在<keep-alive>中的组件，会多出两个生命周期钩子函数。被激活时执行。
		onActivated(() => {
			console.log("被包含在<keep-alive>中的组件，会多出两个生命周期钩子函数。被激活时执行。");
		})
		
		// 比如从 A 组件，切换到 B 组件，A 组件消失时执行。
		onDeactivated(() => {
			console.log("从 A 组件，切换到 B 组件，A 组件消失时执行");
		})
		
		// 当捕获一个来自子孙组件的异常时激活钩子函数
		onErrorCaptured(() => {
			console.log("当捕获一个来自子孙组件的异常时激活钩子函数");
		})
		
		// 显示登陆弹窗
		const showLogin = (url) => {
			const userToken = sessionStorage.getItem("userToken");
			if (!userToken) {
				dataAll.loginAlert = true;
			} else {
				if (dataAll.enterpriseModel == null) {
					//跳转选择类型
					router.push({
						path: '/enterpriseChoice/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					//跳转修改
					router.push({
						path: '/enterpriseEditJump/' + dataAll.enterpriseData.enterpriseQrNumber
					});
				}
			}
		};
 
		return {
			...toRefs(dataAll),
			showLogin,
		}
	}
}
