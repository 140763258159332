import { getCurrentInstance, toRefs, reactive, inject, ref, onBeforeMount, watch } from "vue";
import { useRouter } from 'vue-router'

export default {
	setup(props, context) {
		document.title = "餐饮监管";
		
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		const dataAll = reactive(inject('dataAll'))
		const router = useRouter()
		dataAll.urlImg = [];
		let CategoryArray = ref([])

		let queryCateringArray = () => {
			proxy.$http.request({
				url: proxy.$http.adornUrl(`/app/cateringClass/getClass?type=0`),
				method: 'get',
				data: null,
				headers:{
					token:sessionStorage.getItem("userToken")
				}
			}).then(({data}) =>{
				if (data && data.code === 0) {
					CategoryArray = data.type
					dataAll.cateringArrayFieldName = {text: 'name', value: 'id', children: 'children'}
				} else if(data.code === 401){
					sessionStorage.setItem("userToken","");
					router.push({path:'/enterpriseJump/'+proxy.$router.currentRoute.value.params.id});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		
		onBeforeMount(() => {
			queryCateringArray()
		}) 
	 
		let setCategoryData = () => {
			//餐饮行业分类级联回显处理 
			let categoryPPid = dataAll.enterpriseData.cateringClassPpid;
			let	categoryPid = dataAll.enterpriseData.cateringClassPid;
			let	categoryId = dataAll.enterpriseData.cateringClassId;

			let nameEn = ''
			if(categoryPPid) { 
				let pp = CategoryArray.filter(c => c.id==categoryPPid) 
				nameEn = pp? pp[0].name: ''
				if(categoryPid&&pp[0]) { 
					let p = pp[0].children.filter(i=>i.id==categoryPid) 
					nameEn += p? '/' + p[0].name : ''
					if(categoryId&&p[0]) { 
						let item = p[0].children.filter(i => i.id==categoryId)
						nameEn += item? '/' + item[0].name : ''
					}
				}
				dataAll.enterpriseData.cateringCategoryEn = nameEn
			} 
		} 

		watch(() => dataAll.enterpriseData.enterpriseId, (newval, oldval) => {
			if(newval) { setCategoryData() }
		})

		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};
		
		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}
		
		return {
			...toRefs(dataAll),
			showPopup,
			isNull
		}
	}
}
