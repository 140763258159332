<template>
	<div class="enterprise1">
		<div class="alls">
			<div class="content">
				<div class="content-t">单位名称</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseName)}}</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">主体状态</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseTypeEn)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">许可证期限</div>
					<div class="content-c">
						<span>
							{{isNull(enterpriseData.enterpriseLicenceStime)}} 至
							{{isNull(enterpriseData.enterpriseLicenceEtime)}}
						</span>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="content-t">统一社会信用代码</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseNumber)}}</div>
			</div>
			<div class="content">
				<div class="content-t">许可证号</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseLicence)}}</div>
			</div>
			<div class="content">
				<div class="content-t">最低检查频次</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseMinCheck)}}</div>
			</div>
			<div class="content">
				<div class="content-t">经营地址</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseAddress)}}</div>
			</div>
			<div class="content">
				<div class="content-t">仓库地址</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseWarehouseAddress)}}</div>
			</div>
			<div class="content">
				<div class="content-t">食品生产类别</div>
				<div class="content-c">{{isNull(enterpriseData.productionCategoryEn)}}</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">联系人</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseContact)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">联系人手机号码</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseContactTel)}}</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">负责人</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseHead)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">负责人手机号码</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseHeadTel)}}</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">食品安全管理员</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseContact)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">安全管理员手机号码</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseSecurityTel)}}</div>
				</div>
			</div>
			
			<div class="content">
				<div class="content-t">所属辖区</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseGameEn)}}</div>
			</div>

			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">监管人员</div>
					<div class="content-c">{{ enterpriseData.userTsMapListEn }}</div>
				</div>
				<div class="content-f">
					<div class="content-t">监管等级</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseLevel)}}</div>
				</div>
			</div>
			<div class="content">
				<div class="content-t">经纬度</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseLongitude)}}，{{isNull(enterpriseData.enterpriseLatitude)}}</div>
			</div>

			<div class="content">
				<div class="content-t">许可证照片</div>
				<div class="content-c content-c1">
					<img v-if="enterpriseData.enterpriseLicenceSrc" @click="showPopup(`${baseUrlImg}${enterpriseData.enterpriseLicenceSrc}`)" :src="`${baseUrlImg}${enterpriseData.enterpriseLicenceSrc}`" />
					<span v-else>暂无</span>
				</div>
			</div>

			<div class="content">
				<div class="content-t">营业执照</div>
				<div class="content-c content-c1">
					<img v-if="enterpriseData.enterpriseLicense" @click="showPopup(`${baseUrlImg}${enterpriseData.enterpriseLicense}`)" :src="`${baseUrlImg}${enterpriseData.enterpriseLicense}`" />
					<span v-else>暂无</span>
				</div>
			</div>
			<div class="content">
				<div class="content-t">门头</div>
				<div class="content-c content-c1">
					<img v-show="enterpriseData.enterpriseSurfaceSrcList" v-for="(item,index) in enterpriseData.enterpriseSurfaceSrcList" :key="index" :src="`${baseUrlImg}${item}`" @click="showPopup(`${baseUrlImg}${item}`)" />
					<span v-show="!enterpriseData.enterpriseSurfaceSrcList">暂无</span>
				</div>
			</div>

			<div class="content">
				<div class="content-t">室内图</div>
				<div class="content-c content-c1">
					<img v-show="enterpriseData.enterpriseStoreSrcList" v-for="(item,index) in enterpriseData.enterpriseStoreSrcList" :key="index" :src="`${baseUrlImg}${item}`" @click="showPopup(`${baseUrlImg}${item}`)" />
					<span v-show="!enterpriseData.enterpriseStoreSrcList">暂无</span>
				</div>
			</div>
		</div>

		<van-image-preview :closeable="true" :show-index="false" v-model:show="show" :images="urlImg"></van-image-preview>
	</div>
</template>
<style scoped>
	.alls {
		width: 100%;
		padding-top: 1.173333rem;
		padding-bottom: 1.666667rem;
	}

	.content {
		width: 94%;
		margin-left: 3%;
		border-bottom: 1px #eeeeee solid;
	}

	.content-t {
		color: #2a8df0;
		font-size: 0.373333rem;
		padding-top: 0.186667rem;
		width: 100%;
		text-indent: 0.133333rem;
	}

	.content-c {
		color: #464646;
		font-size: 0.373333rem;
		padding-bottom: 0.186667rem;
		margin-top: 0.053333rem;
		text-indent: 0.133333rem;
		width: 100%;
	}

	.content-c1 {
		overflow-x: scroll;
		white-space: nowrap;
	}

	.content-flex {
		display: flex;
	}

	.content-f {
		flex: 1;
	}

	.content-c span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.content-c img {
		height: 1.973333rem;
		padding: 0.133333rem 0.133333rem 0rem 0.133333rem;
	}

	.c-c-text {
		background-color: #eeeeee;
		border-radius: 0.133333rem;
		width: 100%;
		min-height: 2.133333rem;
		margin-top: 0.133333rem;
	}

	.c-c-text div {
		padding: 0.133333rem;
	}

	::v-deep(.van-popup__close-icon) {
		color: #2a8df0;
	}
</style>
<script>
	import EnterpriseJs1 from "../js/Enterprise-1";
	export default {
		...EnterpriseJs1,
	};
</script>
