import { getCurrentInstance, toRefs, inject, ImagePreview } from "vue";

export default {
	setup(props, context) {
		document.title = "食品监管";
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		const dataAll = inject('dataAll')
		dataAll.urlImg = [];
		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};

		// const getImg = (index) => {
		//     console.log(dataAll.enterpriseData.enterpriseLicenceSrc);
		//     dataAll.images = ['aaa']
		//     ImagePreview({
		//         images: dataAll.images, // 预览图片的那个数组
		//         showIndex:true,
		//         loop:false,
		//         startPosition:index, // 指明预览第几张图
		//     })
		//   }
		
		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}
		
		return {
			...toRefs(dataAll),
			showPopup,
			isNull
		}
	}
}
