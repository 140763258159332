<template>
	<div class="enterpriset">
		<div class="alls">
			<div class="content">
				<div class="content-t">单位名称</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseName)}}</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">所属辖区</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseGameEn)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">统一社会信用代码</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseNumber)}}</div>
				</div>
			</div>
			<div class="content">
				<div class="content-t">单位地址</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseAddress)}}</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">联系人</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseContact)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">联系电话</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseContactTel)}}</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">法定代表人</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseHead)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">单位属性</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseAttribute)}}</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">重点监护单位</div>
					<div class="content-c">
						<div v-if="enterpriseData.enterpriseIsMain==0">否</div>
						<div v-if="enterpriseData.enterpriseIsMain==1">是</div>
					</div>
				</div>
				<div class="content-f">
					<div class="content-t">风险等级</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseLevel)}}</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">最低检查频次</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseMinCheck)}}</div>
				</div>
				<!-- <div class="content-f">
					<div class="content-t">档案号</div>
					<div class="content-c">{{isNull(enterpriseData.enterpriseArchives)}}</div>
				</div> -->
			</div>

			<div class="content">
				<div class="content-t">特种设备类型</div>
				<div class="content-c">
					<span v-show="!enterpriseData.enterpriseFacility">暂无</span>
					<span v-show="enterpriseData.enterpriseFacility" v-for="(item,index) in enterpriseData.enterpriseFacility" :key="index" style="margin-right: .4rem;">{{item.facilityTypeName}}{{item.count}}</span>
				</div>
			</div>
			<div class="content">
				<div class="content-t">操作人员数量</div>
				<div class="content-c">{{isNull(enterpriseData.operationUserCount)}}</div>
			</div>

			<div class="content">
				<div class="content-t">经纬度</div>
				<div class="content-c">{{isNull(enterpriseData.enterpriseLongitude)}}，{{isNull(enterpriseData.enterpriseLatitude)}}</div>
			</div>

			<div class="content">
				<div class="content-t">监管人员</div>
				<div class="content-c">{{ enterpriseData.userTsMapListEn }}</div>
			</div>
			<div class="content">
				<div class="content-t">备注</div>
				<div class="content-c">
					<div class="c-c-text">
						<div>{{isNull(enterpriseData.enterpriseNote)}}</div>
					</div>
				</div>
			</div>
		</div>

		<van-image-preview :closeable="true" :show-index="false" v-model:show="show" :images="urlImg"></van-image-preview>
	</div>
</template>
<style scoped>
	.alls {
		width: 100%;
		padding-top: 1.173333rem;
		padding-bottom: 1.666667rem;
	}

	.content {
		width: 94%;
		margin-left: 3%;
		border-bottom: 1px #eeeeee solid;
	}

	.content-t {
		color: #2a8df0;
		font-size: 0.373333rem;
		padding-top: 0.186667rem;
		width: 100%;
		text-indent: 0.133333rem;
	}

	.content-c {
		color: #464646;
		font-size: 0.373333rem;
		padding-bottom: 0.186667rem;
		margin-top: 0.053333rem;
		text-indent: 0.133333rem;
		width: 100%;
	}

	.content-c1 {
		overflow-x: scroll;
		white-space: nowrap;
	}

	.content-flex {
		display: flex;
	}

	.content-f {
		flex: 1;
	}

	.content-c span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.content-c img {
		height: 1.973333rem;
		padding: 0.133333rem 0.133333rem 0rem 0.133333rem;
	}

	.c-c-text {
		background-color: #eeeeee;
		border-radius: 0.133333rem;
		width: 100%;
		min-height: 2.133333rem;
		margin-top: 0.133333rem;
	}

	.c-c-text div {
		padding: 0.133333rem;
	}

	/deep/.van-popup__close-icon {
		color: #2a8df0;
	}
</style>
<script>
	import EnterpriseJsT from "../js/Enterprise-t";
	export default {
		...EnterpriseJsT,
	};
</script>
