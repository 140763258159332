<template>
	<div>
		<div>
			<div class="top-title">
				<img class="img-title" src="../img/title_img.png" />
				<span>基本信息</span>
				<img class="update-img" src="../img/add.png" @click="showLogin()" />
			</div>

			<div class="button-all">
				<div class="button-van">
					<van-button :disabled="enterpriseData.enterpriseIsUser!=1" :to="{ path: '/user/'+enterpriseData.enterpriseQrNumber}" class="button-div button-lan">
						<span v-if="enterpriseData.enterpriseModel==20">操作人员信息</span>
						<span v-else>从业人员信息</span>
					</van-button>

					<van-button :disabled="enterpriseData.enterpriseIsEquipment!=1" :to="{ path: '/equipment/'+enterpriseData.enterpriseQrNumber}" class="button-div button-cheng">特种设备信息</van-button>
				</div>
			</div>
			<login-alert v-model:showLogin="loginAlert" @close="val=>loginAlert=val"></login-alert>
		</div>

		<enterprises-no v-if="enterpriseModel==null"></enterprises-no>
		<enterprises v-else-if="enterpriseModel==0"></enterprises>
		<enterprises-spsc v-else-if="enterpriseModel==1"></enterprises-spsc>
		<enterprises-spxs v-else-if="enterpriseModel==2"></enterprises-spxs>
		<enterprises-ylqx v-else-if="enterpriseModel>=9&&enterpriseModel<=19"></enterprises-ylqx>
		<enterprises-ts v-else-if="enterpriseModel==20"></enterprises-ts>
	</div>
</template>
<style scoped>
	.top-title {
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
		z-index: 999;
		background-color: #eae6e5;
		color: #232122;
	}

	.top-title .img-title {
		height: 0.533333rem;
		position: absolute;
		top: 0.32rem;
		left: 0.693333rem;
	}

	.top-title span {
		font-size: 0.48rem;
		font-weight: 550;
		letter-spacing: 0.026667rem;
		margin-left: 1.493333rem;
	}

	.top-title .update-img {
		position: absolute;
		top: 0.32rem;
		right: 0.3rem;
		height: 0.533333rem;
	}

	.button-all {
		width: 100%;
		position: fixed;
		bottom: 0rem;
		background-color: #fff;
	}

	.button-van {
		width: 90%;
		margin-left: 5%;
		padding-top: 0.266667rem;
		padding-bottom: 0.4rem;
		position: relative;
	}

	.button-div {
		color: white;
		height: 1.173333rem;
		line-height: 1.173333rem;
		text-align: center;
		border-radius: 0.266667rem;
		font-size: 0.426667rem;
		width: 48%;
		display: inline-block;
	}

	.button-lan {
		background-color: #4492e5;
		border: 1px #4492e5 solid;
	}

	.button-cheng {
		background-color: #ec6f2d;
		border: 1px #ec6f2d solid;
		position: absolute;
		right: 0rem;
	}

	.van-icon-close::before {
		color: #464646;
	}
</style>

<script>
	import EnterpriseJs from "../js/Enterprise";
	import Enterprises from "./Enterprise-0.vue";
	import EnterprisesSpsc from "./Enterprise-1.vue";
	import EnterprisesSpxs from "./Enterprise-2.vue";
	import EnterprisesYlqx from "./Enterprise-y.vue";
	import EnterprisesTs from "./Enterprise-t.vue";
	import EnterprisesNo from "./EnterpriseNo.vue";
	import LoginAlert from "@/components/login";

	export default {
		components: {
			Enterprises,
			EnterprisesSpsc,
			EnterprisesSpxs,
			EnterprisesYlqx,
			EnterprisesTs,
			EnterprisesNo,
			LoginAlert,
		},
		...EnterpriseJs,
	};
</script>
