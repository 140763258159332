<template>
	<div class="enterpriseNo">
		<div class="alls">
			<img src="../img/notET.png" class="imgTsT" />
			<img src="../img/notEC.png" class="imgTsC" />
		</div>
	</div>
</template>
<style scoped>
	.alls {
		width: 100%;
		padding-top: 1.173333rem;
		padding-bottom: 1.666667rem;
		position: relative;
	}

	.imgTsT {
		width: 30%;
		margin-left: 65%;
		margin-top: .266667rem;
	}

	.imgTsC {
		width: 70%;
		margin-left: 15%;
	}
</style>
<script>
	import EnterpriseNo from '../js/Enterprise-no';
	export default {
		...EnterpriseNo
	}
</script>
