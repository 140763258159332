import { getCurrentInstance, toRefs, inject } from "vue";

export default {
	setup(props, context) {
		document.title = "特设监管";
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		
		const dataAll = inject('dataAll')
		
		dataAll.urlImg = [];

		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};
		
		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}
		return {
			...toRefs(dataAll),
			showPopup,
			isNull
		}
	}
}
